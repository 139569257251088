import localforage from 'localforage';
import { set } from 'lodash';
import { validate as uuidValidate } from 'uuid';
import * as Sentry from '@sentry/browser';
import { ActionType } from '../context/actions';
import { MediaId } from '../context/photoConstants';
import { CONNECT_SERVICES_CLIENT } from '../helpers/ConnectServicesClient';
import HeimdallClient from '../helpers/HeimdallClient';
import {
  getAlertsByData,
  getAlertsFromGallery,
  getBlurryAlertData,
  getPhotoIds,
  mergeObjectData,
} from '../helpers/Utils';
import { preInspectDefault } from '../static/constants/default';
import {
  DispatchFunction,
  IAlertData,
  IExteriorData,
  IPreInspectionModel,
  IState,
  Photo,
} from '../types';
import { Field, FieldSection } from '../types/form';
import { getOS } from '../utils/navigator';

export async function getPreInspection(
  uniqueId: string,
): Promise<IPreInspectionModel> {
  try {
    const result = await CONNECT_SERVICES_CLIENT.getPreInspectionByParams(
      uniqueId,
    );

    if (!result.data) {
      return preInspectDefault;
    }

    let currentInspect: IPreInspectionModel = { ...preInspectDefault };
    currentInspect = mergeObjectData(result.data.data, currentInspect);

    return currentInspect;
  } catch (error) {
    Sentry.captureException(error);

    return preInspectDefault;
  }
}

export async function getFormConfiguration(
  uniqueId: string,
): Promise<FieldSection[]> {
  try {
    const result = await CONNECT_SERVICES_CLIENT.getFormByParams(uniqueId);

    if (!result.data || !result.data.data?.sections) {
      return [];
    }

    return result.data.data.sections;
  } catch (error) {
    Sentry.captureException(error);

    return [];
  }
}

export async function syncAlertsWithForm(
  state: IState,
  dispatch: DispatchFunction,
): Promise<void> {
  try {
    if (state && state.formData) {
      // Validate alerts from FormData / Extract Data and Model Data
      const { formData: resultData, alertData: resultAlertData } =
        getAlertsByData(
          state.extractedData,
          state.preInspectionModel,
          state.alertData,
          state.formData,
        );

      // Validate alerts from Photo Data
      const currentPhotos: Photo[] = Object.values(state.photos).map(
        (val: any) => {
          return val;
        },
      );
      const { alertData: currenResultAlertData } = getAlertsFromGallery(
        currentPhotos.filter(
          (f) =>
            (f.notValid && f.notValid === true) ||
            (f.isBlurry && f.isBlurry === true),
        ),
        resultAlertData,
      );

      if (!state.alertData) {
        state.alertData = [];
      }

      if (state.alertData !== null) {
        currenResultAlertData?.forEach((data: IAlertData) => {
          const findObj = state.alertData?.find(
            (dataFind: IAlertData) =>
              dataFind.code === data.code &&
              dataFind.dataCompare === data.dataCompare,
          );

          if (findObj === undefined) {
            state.alertData?.push(data);
          }
        });
      }

      dispatch({
        type: ActionType.SET_PREINSPECTION_FORM,
        payload: resultData,
      });

      dispatch({
        type: ActionType.SET_ALERT_MODEL,
        payload: state.alertData,
      });
    }
  } catch (error) {
    Sentry.captureException(error);
  }
}

export async function savePreInspection(
  formDataSections: FieldSection[] | null,
  state: IState,
  dispatch: DispatchFunction,
  isFinished?: boolean,
  redirect?: () => void,
): Promise<void> {
  try {
    if (formDataSections) {
      const officeInd = await localforage.getItem('indOffice');
      const preInspectObj: any = {};
      preInspectObj.PreInspectionId = state.preInspectionModel.preInspectionId;
      preInspectObj.ApiKey = process.env.REACT_APP_PARTNER_API_KEY;
      preInspectObj.UniqueId = await localforage.getItem('preId');
      preInspectObj.IndOffice =
        officeInd !== undefined && officeInd !== null ? officeInd : false;
      preInspectObj.AcceptConditions =
        (await localforage.getItem('acceptConditions')) ?? false;

      if (isFinished) {
        const stateAlertData = state.alertData ?? [];
        const externalCarImagesIsBlurry =
          localStorage.getItem('externalCarImagesIsBlurry') === 'true';
        if (externalCarImagesIsBlurry === true) {
          stateAlertData.push(getBlurryAlertData);
        }
        preInspectObj.Alerts = stateAlertData;
        preInspectObj.IsFinished = isFinished;
      }

      formDataSections.forEach((fs: FieldSection) => {
        fs.fields.forEach((fv: Field) => {
          if (fv.modelFieldName) {
            let currentValue: any = fv.fieldFormValue;

            if (fv.endPoint) {
              if (
                fv.endPoint.toUpperCase() === 'INTEGER' ||
                fv.endPoint.toUpperCase() === 'DECIMAL'
              ) {
                currentValue = Number(fv.fieldFormValue);
              } else if (fv.endPoint.toUpperCase() === 'BOOLEAN') {
                currentValue =
                  fv.fieldFormValue === '1' ||
                  fv.fieldFormValue === 'ON' ||
                  (typeof fv.fieldFormValue == 'boolean'
                    ? fv.fieldFormValue
                    : false);
              }
            }

            set(preInspectObj, fv.modelFieldName, currentValue);
          }
        });
      });

      if (uuidValidate(preInspectObj.ApiKey)) {
        preInspectObj.Latitude = state.location?.lat ?? '0';
        preInspectObj.Longitude = state.location?.lng ?? '0';
        preInspectObj.OperatingSystem = getOS();
        preInspectObj.UserAgent = navigator.userAgent;
        //TODO: Add debounce implementation for this process
        setTimeout(async () => {
          const result = await CONNECT_SERVICES_CLIENT.addOrEditPreInspect(
            preInspectObj,
          );

          if (result.data) {
            const { response } = result.data.data;
            if (response) {
              // eslint-disable-next-line no-console
              console.log('Info :: Autosave is successfully');

              if (+response > 0) {
                dispatch({
                  type: ActionType.SET_PREINSPECTION_ID,
                  payload: +response,
                });
                // redirect to finish page
                if (redirect) {
                  redirect();
                }
              } else {
                // eslint-disable-next-line no-console
                console.log(
                  'Alert :: State model is undefined or preInspectionId is not a valid value.',
                );
              }
            }
          }
        }, 1500);
      }
    }
  } catch (error) {
    Sentry.captureException(error);
  }
}

export async function sendToDamageAnalysis(
  state: IState,
  typeZone: string,
): Promise<void> {
  if (state && typeZone && typeZone.length > 0) {
    const webHookUrl = process.env.REACT_APP_CONNECT_API + '/api/v1/damages';
    const listImages: IExteriorData[] = [
      {
        id: state.preInspectionModel.preInspectionId,
        zoneName: 'FRONTAL',
        s3Url: (state.photos.VEHICLE_EXTERIOR_FRONT as Photo)?.url as string,
        webHook: webHookUrl,
      },
      {
        id: state.preInspectionModel.preInspectionId,
        zoneName: 'LATERAL PASAJERO',
        s3Url: (state.photos.VEHICLE_EXTERIOR_RIGHT as Photo)?.url as string,
        webHook: webHookUrl,
      },
      {
        id: state.preInspectionModel.preInspectionId,
        zoneName: 'LATERAL PASAJERO FRONTAL',
        s3Url: (state.photos.VEHICLE_EXTERIOR_RIGHT_FRONT as Photo)
          ?.url as string,
        webHook: webHookUrl,
      },
      {
        id: state.preInspectionModel.preInspectionId,
        zoneName: 'LATERAL PASAJERO TRASERO',
        s3Url: (state.photos.VEHICLE_EXTERIOR_RIGHT_BACK as Photo)
          ?.url as string,
        webHook: webHookUrl,
      },
      {
        id: state.preInspectionModel.preInspectionId,
        zoneName: 'LATERAL CONDUCTOR',
        s3Url: (state.photos.VEHICLE_EXTERIOR_LEFT as Photo)?.url as string,
        webHook: webHookUrl,
      },
      {
        id: state.preInspectionModel.preInspectionId,
        zoneName: 'LATERAL CONDUCTOR FRONTAL',
        s3Url: (state.photos.VEHICLE_EXTERIOR_LEFT_FRONT as Photo)
          ?.url as string,
        webHook: webHookUrl,
      },
      {
        id: state.preInspectionModel.preInspectionId,
        zoneName: 'LATERAL CONDUCTOR TRASERO',
        s3Url: (state.photos.VEHICLE_EXTERIOR_LEFT_BACK as Photo)
          ?.url as string,
        webHook: webHookUrl,
      },
      {
        id: state.preInspectionModel.preInspectionId,
        zoneName: 'TRASERO',
        s3Url: (state.photos.VEHICLE_EXTERIOR_BACK as Photo)?.url as string,
        webHook: webHookUrl,
      },
    ];
    const client = new HeimdallClient();
    const currentExteriorDataZone = listImages.filter(
      (f) => f.zoneName && f.zoneName.toUpperCase() === typeZone.toUpperCase(),
    )[0];

    if (currentExteriorDataZone) {
      // eslint-disable-next-line no-console
      console.log('Info :: Send the exterior vehicle photos for analysis.');

      await client.predictVehicle(currentExteriorDataZone);
    }
  }
}

export async function getVehicleSide(
  state: IState,
  typeZone: string,
): Promise<string> {
  const client = new HeimdallClient();
  const listImages: IExteriorData[] = [
    {
      zoneName: 'FRONTAL',
      detectZone: 'FRONT-SIDE',
      s3Url: (state.photos.VEHICLE_EXTERIOR_FRONT as Photo)?.url as string,
    },
    {
      zoneName: 'LATERAL PASAJERO',
      detectZone: 'RIGHT-SIDE',
      s3Url: (state.photos.VEHICLE_EXTERIOR_RIGHT as Photo)?.url as string,
    },
    {
      zoneName: 'LATERAL PASAJERO FRONTAL',
      detectZone: 'RIGHT-FRONT-SIDE',
      s3Url: (state.photos.VEHICLE_EXTERIOR_RIGHT_FRONT as Photo)
        ?.url as string,
    },
    {
      zoneName: 'LATERAL PASAJERO TRASERO',
      detectZone: 'RIGHT-BACK-SIDE',
      s3Url: (state.photos.VEHICLE_EXTERIOR_RIGHT_BACK as Photo)?.url as string,
    },
    {
      zoneName: 'LATERAL CONDUCTOR',
      detectZone: 'LEFT-SIDE',
      s3Url: (state.photos.VEHICLE_EXTERIOR_LEFT as Photo)?.url as string,
    },
    {
      zoneName: 'LATERAL CONDUCTOR FRONTAL',
      detectZone: 'LEFT-FRONT-SIDE',
      s3Url: (state.photos.VEHICLE_EXTERIOR_LEFT_FRONT as Photo)?.url as string,
    },
    {
      zoneName: 'LATERAL CONDUCTOR TRASERO',
      detectZone: 'LEFT-BACK-SIDE',
      s3Url: (state.photos.VEHICLE_EXTERIOR_LEFT_BACK as Photo)?.url as string,
    },
    {
      zoneName: 'TRASERO',
      detectZone: 'BACK-SIDE',
      s3Url: (state.photos.VEHICLE_EXTERIOR_BACK as Photo)?.url as string,
    },
  ];

  if (state && typeZone && typeZone.length > 0) {
    const currentExteriorDataZone = listImages.filter(
      (f) => f.zoneName && f.zoneName.toUpperCase() === typeZone.toUpperCase(),
    )[0];

    const resultAnalysis = await client.vehicleZoneExtraction(
      currentExteriorDataZone,
    );

    return resultAnalysis && resultAnalysis.length > 0
      ? resultAnalysis
      : 'NONE';
  }

  return 'NONE';
}

export async function getPreInspectMedia(uniqueId: string): Promise<any> {
  try {
    const result = await CONNECT_SERVICES_CLIENT.getMedia(uniqueId);
    const arrMedia: { [key in MediaId]?: Photo | Photo[] } = {};
    const mediaTemplate = (id: MediaId): Photo | Photo[] => {
      return id === MediaId.ACCESSORIES
        ? ([] as Photo[])
        : {
            id: id,
            url: undefined,
            attemps: 0,
            notValid: false,
            notValidZone: false,
          };
    };

    if (!result.data) {
      return [];
    }

    const arrMediaData: any[] = Array.from(result.data.data);

    const photoIds = getPhotoIds();
    Object.values(MediaId)
      .filter((media) => photoIds.includes(media))
      .forEach((value) => {
        const currentMedia = arrMediaData.find(
          (m: any) => m.url !== undefined && String(m.url).indexOf(value) > -1,
        );
        if (currentMedia !== undefined) {
          const mediaData: Photo = {
            id: value,
            url: currentMedia.url,
            attemps: 0,
            notValid: false,
            notValidZone: false,
          };

          arrMedia[value] = mediaData;
        } else {
          arrMedia[value] = mediaTemplate(value);
        }
      });

    return arrMedia as { [key in MediaId]: Photo };
  } catch (error) {
    Sentry.captureException(error);
  }
}

export async function requestPreInspectionFinish(
  preinspectId: number,
): Promise<string> {
  try {
    const result = await CONNECT_SERVICES_CLIENT.getPreInspectionFinishStatus(
      preinspectId,
    );

    return result.data;
  } catch (error) {
    Sentry.captureException(error);

    return 'EXPIRE';
  }
}

export async function sharedPreInspection(
  preinspectId: number,
  companyId: number,
  type: string,
  email: string,
  link: string,
): Promise<string> {
  try {
    const result = await CONNECT_SERVICES_CLIENT.sharedPreInspection({
      Id: preinspectId,
      CompanyId: companyId,
      Type: type.toUpperCase(),
      Email: email,
      Link: link,
    });

    return result.data;
  } catch (error) {
    Sentry.captureException(error);

    return 'EMAIL_NOT_SEND';
  }
}

export async function getIsValidZones(preInspectId: number): Promise<any> {
  try {
    const result = await CONNECT_SERVICES_CLIENT.getIsValidZones(preInspectId);

    return result.data;
  } catch (error) {
    Sentry.captureException(error);

    return false;
  }
}
