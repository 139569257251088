import validator from 'validator';
import { set } from 'lodash';
import { ActionType } from '../context/actions';
import {
  MediaId,
  PHOTO_DEFINITIONS,
  PHOTO_SECTIONS,
  PhotoDefinition,
  PhotoSectionDefinition,
  PhotoSectionId,
} from '../context/photoConstants';
import {
  BrandsId,
  CountryCode,
  DispatchFunction,
  IAlertData,
  IPreInspectionModel,
  Photo,
} from '../types';
import { Dependency, Field, FieldSection, FieldTypes } from '../types/form';
import { Company } from '../interfaces/brand';
import { brandingInfo } from '../static/constants/brands';

export const filterSectionsByDependencies = (
  s: FieldSection,
  sections: FieldSection[],
): boolean => {
  if (!s.visible) {
    return false;
  }

  if (s.dependencyJson) {
    s.dependency = JSON.parse(s.dependencyJson);
  }

  if (!s.dependency) {
    return true;
  }

  const resultDependencies: boolean[] = [];
  s.dependency.forEach((fe: Dependency) => {
    const dFieldName = fe.Name;
    const dSectionId = fe.SectionId;
    const dExpectedValue = fe?.ExpectedValue;

    const fieldValue = sections
      .find((s) => s.formSectionId === dSectionId)
      ?.fields.find((f) => f.name === dFieldName)?.value;
    resultDependencies.push(fieldValue === dExpectedValue);
  });

  return (
    s.dependency.length === resultDependencies.filter((f) => f === true).length
  );
};

export const filterTabs = (
  sectionTabs: FieldSection[],
  sections: FieldSection[],
): FieldSection[] => {
  const tabs = sectionTabs.filter((s) =>
    filterSectionsByDependencies(s, sections),
  );

  return tabs;
};

export const checkDependencyFields = (
  s: FieldSection,
  sectionsInit: FieldSection[] | null,
): Field[] => {
  const checkFields = s.fields.map((f: Field) => {
    if (f.dependency?.name) {
      const field = s.fields.find(
        (f2: Field) =>
          f2.idName === f.dependency?.name &&
          f2.formSectionId === f.dependency?.sectionId,
      );

      if (field) {
        const isExpectedValue = field.fieldFormValue !== f.dependency?.expectedValue && (field.fieldFormType === FieldTypes.MULTIDROPDOWN.toUpperCase() && !field.fieldFormValue?.includes(f.dependency?.expectedValue));
        
        if (isExpectedValue) {
          f.visible = false;
          f.required = false;
          f.fieldFormValue = '';
        } else {
          const fieldSection = sectionsInit?.find(
            (s2: FieldSection) => s2.formSectionId === f.formSectionId,
          );
          const fieldInit = fieldSection?.fields.find(
            (f3: Field) => f3.idName === f.dependency?.name,
          );
          f.visible = fieldInit?.visible;
          f.required = fieldInit?.required;
        }
      }
    }

    return f;
  });

  return checkFields;
};

export const validationForm = (
  sectionId: number,
  sections: FieldSection[],
  sectionsInit: FieldSection[] | null,
): any => {
  const validSections: boolean[] = [];
  const validRegexFields: boolean[] = [];
  let isValidFormForRequired = false;
  let isValidFormForRegex = false;
  let fieldsWithRegex = 0;
  const countSectionsToValidate = sections.filter(
    (f: FieldSection) => f.parentSectionId === sectionId,
  ).length;

  const checkSections = sections.map((s: FieldSection) => {
    // State before revision
    s.fields = checkDependencyFields(s, sectionsInit);
    let fieldsUpdated = s.fields;

    if (s.parentSectionId === sectionId) {
      const totalRequired = s.fields.filter(
        (f: Field) => f.required && f.required === true,
      ).length;

      const fieldCheckBox = s.fields.find(
        (f) => f.fieldFormType === 'CHECKBOX' && f.required === true,
      );

      let totalWithValue = s.fields.filter(
        (f: Field) =>
          f.required &&
          f.required === true &&
          f.fieldFormValue &&
          String(f.fieldFormValue).length > 0,
      ).length;

      if (fieldCheckBox) {
        totalWithValue =
          fieldCheckBox.sendFieldType === 'BOOLEAN' &&
          (fieldCheckBox.fieldFormValue === false ||
            fieldCheckBox.fieldFormValue === '0')
            ? totalWithValue - 1
            : totalWithValue;
      }

      isValidFormForRequired = totalRequired === totalWithValue;

      fieldsUpdated = s.fields.map((f: Field) => {
        f.hasError =
          f.required &&
          f.required === true &&
          (!f.fieldFormValue || f.fieldFormValue.length === 0);
        f.isDisabled = f.hasError ? false : f.isDisabled;

        if (!f.hasError) {
          if (f.endPoint && f.endPoint.length > 0 && f.fieldFormValue) {
            fieldsWithRegex++;
            const validationValue = regexValidation(
              f.fieldFormValue,
              f.endPoint,
            );
            f.hasError = validationValue.length === 0;
            validRegexFields.push(!f.hasError);
            isValidFormForRequired =
              isValidFormForRequired && f.hasError
                ? false
                : isValidFormForRequired;
          }
        }

        return f;
      });

      validSections.push(isValidFormForRequired);
    }

    return { ...s, fields: fieldsUpdated };
  });

  const sectionsAreValid =
    countSectionsToValidate === validSections.filter((f) => f === true).length;

  isValidFormForRegex =
    validRegexFields.filter((f) => f === true).length === fieldsWithRegex;

  return {
    valid: sectionsAreValid && isValidFormForRegex,
    sections: checkSections,
  };
};

/**
 * @param {any} currentValue
 * @param {string} fieldType
 */
const regexValidation = (currentValue: any, fieldType?: string): string => {
  currentValue = String(currentValue).trim();
  if (fieldType && fieldType.toUpperCase() === 'ONLYLETTERS') {
    currentValue = validator.isAlpha(validator.blacklist(currentValue, ' '))
      ? currentValue
      : '';
  }

  if (fieldType && fieldType.toUpperCase() === 'PERSONALID') {
    const filter = /^[0-9-]+$/;
    currentValue = filter.test(currentValue) ? currentValue : '';
  }

  if (fieldType && fieldType.toUpperCase() === 'EMAIL') {
    currentValue = validator.isEmail(currentValue) ? currentValue : '';
  }

  return currentValue;
};

export const getWelcomeText = (brandStr: string): string => {
  return brandingInfo[brandStr].welcomeMsg ?? '';
};

export const getPagesToRemove = (brandStr: string): number[] => {
  return brandingInfo[brandStr].pagesToRemove ?? [];
};

export const getDocumentGalleries = (brandStr: string): string[] => {
  return brandingInfo[brandStr].galleryDocument ?? [];
};

export const getExteriorGalleries = (brandStr: string): string[] => {
  return brandingInfo[brandStr].galleryExterior ?? [];
};

export const getTotalPages = (brandStr: string): number => {
  return brandingInfo[brandStr].pages ?? 0;
};

export const getPageIndex = (brandStr: string, pageName: string): string => {
  switch (pageName) {
    case 'documents':
      return brandingInfo[brandStr].keyDocumentPage ?? '0';
    case 'time':
      return brandingInfo[brandStr].keyTimePage ?? '0';
    case 'photos':
      return brandingInfo[brandStr].keyPhotosPage ?? '0';
    case 'location':
      return brandingInfo[brandStr].keyLocationPage ?? '0';
  }

  return '0';
};

export const setFormFieldValue = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  currentValue: any,
  dispatch: DispatchFunction,
  currentPreInspect: IPreInspectionModel | null,
  currentSections: FieldSection[] | null,
): void => {
  const [fieldName, formSection] = currentValue.name.split('|');
  let fieldValue =
    typeof currentValue.value === 'boolean'
      ? currentValue.value
      : currentValue.value
      ? String(currentValue.value).toUpperCase()
      : undefined;

  if (fieldName === 'preSignature') {
    fieldValue = currentValue.value ? currentValue.value : undefined;
  } else if (fieldName === 'preTermsAcceptance') {
    fieldValue = currentValue ? currentValue.value : false;
  }

  const updateSections = currentSections
    ? currentSections.map((section: FieldSection) => {
        if (section.formSectionId === Number(formSection)) {
          const updatedFields = section.fields.map((field: Field) => {
            if (field.idName.toLowerCase() === fieldName.toLowerCase()) {
              field.fieldFormValue = fieldValue;
            }

            return field;
          });
          section.fields = updatedFields;
        }

        return section;
      })
    : [];

  if (currentPreInspect) {
    set(currentPreInspect, fieldName, fieldValue);
  }

  dispatch({
    type: ActionType.SET_PREINSPECTION_MODEL,
    payload: currentPreInspect,
  });

  dispatch({
    type: ActionType.SET_PREINSPECTION_FORM,
    payload: updateSections,
  });
};

export const getCountryFormat = (): any => {
  const countryCode = localStorage.getItem('countryCode');
  const data = {
    dateTimeFormat: 'MM/dd/yyyy',
  };

  switch (countryCode) {
    case CountryCode.PAN:
    case CountryCode.CRC:
      data.dateTimeFormat = 'dd/MM/yyyy';
      break;
    default:
      break;
  }

  return data;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const mergeObjectData = (source: any, target: any) => {
  Object.keys(source).forEach((key) => {
    try {
      target[key] = source[key];
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('The target field is not found.');
      // eslint-disable-next-line no-console
      console.log(error);
    }
  });

  return target;
};

/**
 *
 * @param value The input Value.
 * @param template Template format for the value. Example: ##,####.##
 * @param defaultTag The tag value for merge with the input value. Example: #
 * @param useTemplateLength Return the complete format length or only the input value length.
 * @returns
 */
export const formatValue = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  value: any,
  template: string,
  defaultTag: string,
  useTemplateLength: boolean,
): string => {
  const arrValues: string[] = [];
  const valueStr = String(value);

  let arrTemplate: string[] = [];
  let finalValue = '';
  let idxReference = 1;

  // Process template
  for (let i = 0; i < template.length; i++) {
    const chardata = template.charAt(i);
    if (chardata.length > 0) {
      arrTemplate.push(chardata);
    }
  }

  // Process value
  for (let i = valueStr.length - 1; i >= 0; i--) {
    const chardata = valueStr.charAt(i);
    if (chardata.length > 0) {
      arrValues.push(chardata);
    }
  }

  if (arrValues.length > arrTemplate.filter((f) => f === defaultTag).length) {
    const diffIndex =
      arrValues.length - arrTemplate.filter((f) => f === defaultTag).length;
    arrTemplate = [];
    for (let i = 0; i < diffIndex; i++) {
      template = defaultTag + template;
    }

    // Update template
    for (let i = 0; i < template.length; i++) {
      const chardata = template.charAt(i);
      if (chardata.length > 0) {
        arrTemplate.push(chardata);
      }
    }
  }

  const arrTemplateWithValues: string[] = new Array(arrTemplate.length);
  let lastIndex = 0;

  for (let i = arrTemplate.length - 1; i >= 0; i--) {
    if (arrTemplate[i] === defaultTag) {
      // if has value print the value if not then print the tag
      if (idxReference <= arrValues.length) {
        arrTemplateWithValues[i] = arrValues[idxReference - 1];
        lastIndex = i;
      } else {
        arrTemplateWithValues[i] = defaultTag;
      }
      // Next position for arrValue
      idxReference++;
    } else {
      arrTemplateWithValues[i] = arrTemplate[i];
    }
  }

  for (let i = 0; i <= arrTemplateWithValues.length - 1; i++) {
    if (arrTemplateWithValues[i] !== undefined) {
      if (useTemplateLength) {
        finalValue += arrTemplateWithValues[i];
      } else if (i >= lastIndex) {
        finalValue += arrTemplateWithValues[i];
      }
    }
  }

  return finalValue;
};

export const copyToClipboard = (value: string): void => {
  const el = document.createElement('textarea');
  el.value = value;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
};

export const CHECKS_ALERTS = [
  { idName: 'preVehiclePlate', property: 'plate', code: 'ALERT-RUV-PLATE' },
  { idName: 'preVehicleType', property: 'type', code: 'ALERT-RUV-TYPE' },
  { idName: 'preVehicleColor', property: 'color', code: 'ALERT-RUV-COLOR' },
  { idName: 'preVehicleBrand', property: 'brand', code: 'ALERT-RUV-BRAND' },
  { idName: 'preVehicleModel', property: 'model', code: 'ALERT-RUV-MODEL' },
  { idName: 'preVehicleYear', property: 'year', code: 'ALERT-RUV-YEAR' },
  { idName: 'preVehicleVin', property: 'vin', code: 'ALERT-RUV-VIN' },
  { idName: 'preVehicleMotor', property: 'motor', code: 'ALERT-RUV-MOTOR' },
  {
    idName: 'preTransmission',
    property: 'transmission',
    code: 'ALERT-RUV-TRANSMISSION',
  },
  {
    idName: 'preInsuranceAmount',
    property: 'insuredAmount',
    code: 'ALERT-RUV-AMOUNT-COMPARE',
  },
  { idName: 'preIsImported', property: null, code: 'ALERT-VEH-IMPORTED' },
];

export const DEPRECIATION_LIST = [
  {
    brand: BrandsId.INTER_DE_SEGUROS,
    data: [
      { year: 0, percent: 0 },
      { year: 1, percent: 15 },
      { year: 2, percent: 15 },
      { year: 3, percent: 15 },
      { year: 4, percent: 15 },
      { year: 5, percent: 10 },
      { year: 6, percent: 10 },
    ],
  },
  // Other brands
];

export const currencyFormat = (n: number): string => {
  return n.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
};

const generalAlertValidations = (
  currentAlertData: IAlertData[] | null,
  field: Field,
  code: string,
): IAlertData[] => {
  const currentValue = field.fieldFormValue;

  switch (code) {
    case 'ALERT-VEH-IMPORTED':
      if (currentAlertData) {
        if (field.idName === 'preIsImported' && currentValue === '1') {
          currentAlertData.push({
            code: code,
            dataCompare: 'El usuario ha indicado que el vehículo es importado.',
          });
        } else {
          const existElem = currentAlertData.findIndex(
            (alert: IAlertData) => alert.code === code,
          );
          if (existElem > 0) {
            currentAlertData.splice(existElem, 1);
          }
        }
      }
      break;
    default:
      break;
  }

  return currentAlertData ?? [];
};

const ruvAlertValidations = (
  preinspectData: any,
  inputData: any,
  field: Field,
  code: string,
  property: string,
  idName: string,
): IAlertData[] => {
  const alertData: IAlertData[] = [];
  const currentValue = field.fieldFormValue;
  let extractData = String(inputData[property]);

  if (
    extractData &&
    extractData.toUpperCase() !== 'UNDEFINED' &&
    extractData.toUpperCase() !== 'NULL'
  ) {
    if (
      currentValue &&
      extractData.toUpperCase() === currentValue.toUpperCase()
    ) {
      field.isDisabled =
        currentValue.length > 0 &&
        currentValue !== undefined &&
        currentValue !== 'UNDEFINED';
    } else if (
      currentValue &&
      extractData.toUpperCase() !== currentValue.toUpperCase() &&
      extractData.length > 0
    ) {
      if (idName === 'preInsuranceAmount') {
        // Validate year
        const currentYear = new Date().getFullYear();
        let ruvYear = preinspectData.preVehicleYear;
        if (
          inputData['year'] !== undefined &&
          inputData['year'] !== 'UNDEFINED' &&
          inputData['year'] !== null &&
          inputData['year'].length > 0
        ) {
          ruvYear = +inputData['year'];
        }

        // Extract depreciation
        const diffYear = currentYear - ruvYear;
        const currentDepreciation = DEPRECIATION_LIST.filter(
          (data: any) => data.brand === preinspectData.preCompanyId,
        )[0];

        // Validate amount
        const insuredAmount = parseInt(currentValue.replace(/,/g, ''));
        let ruvAmount = parseInt(extractData.replace(/,/g, ''));

        // Apply alerts
        if (insuredAmount > ruvAmount) {
          alertData.push({
            code: code,
            dataCompare: currentValue + ' is greater than ' + extractData,
          });
        }

        // Calculate depreciation
        let depreciationAmount = ruvAmount;
        let selectedPercent = 0;

        const arrDepreciation = currentDepreciation.data.sort(
          (a: any, b: any) => {
            return a.year - b.year;
          },
        );

        const existYear = arrDepreciation.some(
          (item) => item.year === diffYear,
        );

        // If the diff year isn't contains in the list
        if (!existYear) {
          const lastItem = arrDepreciation[arrDepreciation.length - 1];

          // Add all years between the last year of the array and the value of the diff year
          for (
            let lastYear = lastItem.year + 1;
            lastYear <= diffYear;
            lastYear++
          ) {
            arrDepreciation.push({
              year: lastYear,
              percent: lastItem.percent,
            });
          }
        }

        arrDepreciation.forEach((deprec: any) => {
          if (diffYear >= deprec.year) {
            if (deprec.percent && deprec.percent > 0) {
              selectedPercent = deprec.percent;
              depreciationAmount =
                depreciationAmount -
                (depreciationAmount * deprec.percent) / 100;
            }
          }
        });

        ruvAmount = depreciationAmount;

        if (selectedPercent > 0) {
          alertData.push({
            code: 'ALERT-RUV-AMOUNT-DEPRECIATION',
            dataCompare: `Se aplicó depreciación a la suma asegurada de un ${selectedPercent}% correspondiente al año ${diffYear} de fabricación del vehículo.`,
          });
          extractData = currencyFormat(ruvAmount);
        }
      } else if (currentValue.length > 0 && extractData.length > 0) {
        alertData.push({
          code: code,
          dataCompare: currentValue + '=>' + extractData,
        });
      }
      field.fieldFormValue = extractData.toUpperCase();
    } else if (
      (!currentValue || currentValue.length === 0) &&
      extractData.length > 0
    ) {
      field.fieldFormValue = extractData.toUpperCase();
    }
  } else {
    // eslint-disable-next-line no-console
    console.log(
      'RUV-INFO :: Extract Data Not Found :: Property => ' + property,
    );
  }

  return alertData;
};

export const getAlertsByData = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  inputData: any,
  preinspectData: IPreInspectionModel,
  currentAlertData: IAlertData[] | null,
  fields: FieldSection[],
): any => {
  const alertData: IAlertData[] = [];
  let updateForm: FieldSection[] = [];

  updateForm = fields.map((section: FieldSection) => {
    if (
      section.level === '2' &&
      section.name !== undefined &&
      ['PRE_GENERAL', 'PRE_VEHICLE'].includes(section.name)
    ) {
      section.fields.forEach((field: Field) => {
        const resultAlert: any = CHECKS_ALERTS.find(
          (f: any) => f.idName === field.idName,
        );

        if (resultAlert) {
          const { idName, property, code } = resultAlert;

          // Validate in the preinspection form data
          if (property === null) {
            generalAlertValidations(currentAlertData, field, code).forEach(
              (alert: IAlertData) => {
                alertData.push(alert);
              },
            );
          } else {
            if (inputData === null || inputData === undefined) {
              // eslint-disable-next-line no-console
              console.log('RUV-INFO :: Alert Empty Model');
            } else {
              // Use for RUV info or when extract data from file
              ruvAlertValidations(
                preinspectData,
                inputData,
                field,
                code,
                property,
                idName,
              ).forEach((alert: IAlertData) => {
                alertData.push(alert);
              });
            }
          }
        } else {
          // eslint-disable-next-line no-console
          console.log('INFO :: Alert not found :: Field => ' + field.idName);
        }
      });
    }

    return section;
  });

  return {
    formData: updateForm,
    extractedData: inputData,
    alertData: alertData,
  };
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getAlertsFromGallery = (
  photos: Photo[],
  currentAlertData: IAlertData[] | null,
) => {
  if (!currentAlertData) {
    currentAlertData = [];
  }

  photos
    .filter((p: Photo) => !p.isBlurry)
    .forEach((data: Photo, index: number) => {
      if (currentAlertData) {
        currentAlertData.push({
          code: 'ALERT-PHOTO-NOT-VALID-' + String(index),
          dataCompare:
            PHOTO_DEFINITIONS[data.id].alertDescription ??
            'No hay referencias.',
        });
      }
    });

  return {
    alertData: currentAlertData,
  };
};

export const menusPhotoSectionByBranding = (
  branding: string,
): [string, PhotoSectionDefinition][] => {
  const defaultSections = Object.entries(PHOTO_SECTIONS).map(
    (dataSection: [string, PhotoSectionDefinition]) => {
      return dataSection;
    },
  );

  switch (branding.toUpperCase()) {
    case Company.CONNECT:
      return defaultSections;
    case Company.IS:
      return defaultSections;
    case Company.INS:
      return defaultSections;
    case Company.SURA:
      return defaultSections.filter((d: [string, PhotoSectionDefinition]) =>
        ['SURA_PHOTOS'].includes(d[0]),
      );
    default: {
      return defaultSections;
    }
  }
};

export const photoSectionsByBranding = (
  branding: string,
  photoSectionId: PhotoSectionId,
): PhotoSectionDefinition => {
  switch (branding.toUpperCase()) {
    case Company.CONNECT:
      return PHOTO_SECTIONS[photoSectionId];
    case Company.IS:
      return PHOTO_SECTIONS[photoSectionId];
    case Company.BCR:
      return PHOTO_SECTIONS[photoSectionId];
    case Company.SURA: {
      const sura_photoSections = [PHOTO_SECTIONS[PhotoSectionId.SURA_PHOTOS]];

      return sura_photoSections.filter(
        (f) => f === PHOTO_SECTIONS[photoSectionId],
      )[0];
    }
    case Company.INS:
      return PHOTO_SECTIONS[photoSectionId];
    default:
      return PHOTO_SECTIONS[photoSectionId];
  }
};

export const photoDefinitionsByBranding = (
  branding: string,
  section: MediaId,
): PhotoDefinition => {
  switch (branding.toUpperCase()) {
    case Company.CONNECT:
      return PHOTO_DEFINITIONS[section];
    case Company.IS:
      return PHOTO_DEFINITIONS[section];
    case Company.BCR:
      return PHOTO_DEFINITIONS[section];
    case Company.SURA: {
      const sura_photoDefinitions = [
        PHOTO_DEFINITIONS[MediaId.VEHICLE_EXTERIOR_FRONT],
        PHOTO_DEFINITIONS[MediaId.VEHICLE_EXTERIOR_BACK],
        PHOTO_DEFINITIONS[MediaId.VEHICLE_EXTERIOR_LEFT],
        PHOTO_DEFINITIONS[MediaId.VEHICLE_EXTERIOR_RIGHT],
        PHOTO_DEFINITIONS[MediaId.VEHICLE_INTERIOR_ODOMETER],
        PHOTO_DEFINITIONS[MediaId.VEHICLE_INTERIOR_VIN],
        PHOTO_DEFINITIONS[MediaId.VEHICLE_INTERIOR_1],
      ];

      return sura_photoDefinitions.filter(
        (f) => f === PHOTO_DEFINITIONS[section],
      )[0];
    }
    case Company.INS:
      return PHOTO_DEFINITIONS[section];
    default:
      return PHOTO_DEFINITIONS[section];
  }
};

export const mediaSectionByBranding = (branding: string): string[] => {
  switch (branding.toUpperCase()) {
    case Company.CONNECT:
      return Object.keys(PHOTO_DEFINITIONS);
    case Company.IS:
      return Object.keys(PHOTO_DEFINITIONS);
    case Company.BCR:
      return Object.keys(PHOTO_DEFINITIONS);
    case Company.SURA: {
      const sura_photoDefinitions = [
        MediaId.VEHICLE_EXTERIOR_FRONT,
        MediaId.VEHICLE_EXTERIOR_BACK,
        MediaId.VEHICLE_EXTERIOR_LEFT,
        MediaId.VEHICLE_EXTERIOR_RIGHT,
        MediaId.VEHICLE_INTERIOR_VIN,
        MediaId.VEHICLE_INTERIOR_1,
      ];

      return Object.values(sura_photoDefinitions);
    }
    case Company.INS:
      return Object.keys(PHOTO_DEFINITIONS);
    default:
      return Object.keys(PHOTO_DEFINITIONS);
  }
};

export const getPhotoIds = (): string[] => {
  const allPhotoIds: string[] = [];
  for (const sectionId in PHOTO_SECTIONS) {
    if (sectionId in PHOTO_SECTIONS) {
      const section = PHOTO_SECTIONS[
        sectionId as PhotoSectionId
      ] as PhotoSectionDefinition;
      if (section.photoIds instanceof Array && section.visible) {
        allPhotoIds.push(...section.photoIds);
      }
    }
  }

  return allPhotoIds;
};

export const getBlurryAlertData: IAlertData = {
  code: 'ALERT-PHOTO-BLURRY',
  dataCompare:
    'Fotos externas del vehículo detectadas como NO válidas en la galería (Subidas por reintentos, el motivo puede ser por imágenes borrosas o zonas no correspondientes).',
};
